<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Resultados
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Resultados
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">

            <navigator tab="prova"></navigator>

            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-2" v-if="mostrarSelectInstituicoes">
                  <div class="form-group">
                    <label for="ano">Instituições</label>
                      <select2
                        id="instituicoes"
                        v-model="instituicoes"
                        :settings="{ multiple: true, placeholder: 'Selecione' }"
                        :options="instituicoesSelectList"
                        @change="onChangeInstituicoes($event)"
                      />
                  </div>
                </div>
                <div class="col-md-2" id="ano-col">
                  <div class="form-group">
                    <label for="ano">Ano</label>
                    <select
                      id="ano"
                      class="form-control"
                      :disabled="anos.length <= 0"
                      @change="onChangeAno($event)"
                    >
                      <option v-for="ano in anosSelect" :key="ano.id" :value="ano.id">
                        {{ ano.titulo }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2" id="componente-curricular-col">
                  <div class="form-group">
                    <label for="ano">Componente Curricular</label>
                    <select
                      id="disciplina"
                      class="form-control"
                      @change="onChangeDisciplina($event)"
                    >
                      <option
                        v-for="item in disciplinasSelect"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__results table-striped table-borderless table-hover text-center"
              >
                <thead>
                  <tr>
                    <th class="text-left pl-4" scope="col">Componente Curricular</th>
                    <th scope="col">Ano</th>
                    <th scope="col">Turma</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Média Turma</th>
                    <th scope="col">Desempenho</th>
                    <th scope="col">Data</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in this.provasFiltradas" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="item.disciplina.text"
                    ></td>
                    <td
                      class="text-center"
                      v-html="item.turma.anoEnsino.titulo"
                    ></td>
                    <td
                      class="text-center"
                      v-html="item.turma.titulo"
                    ></td>
                    <td class="text-left" v-html="`<strong>${item.titulo}</strong>`"></td>
                    <td class="text-center" v-if="item.estatisticaProva"><strong>{{ calcularMedia(item) }}</strong></td>
                    <td class="text-center" v-else>-</td>
                    <td class="text-left" v-if="item.estatisticaProva">
                      <div>
                        <b-progress>
                          <b-progress-bar
                            :value="item.estatisticaProva.qtdQuestoesCorretas * 100"
                            variant="success"
                          ></b-progress-bar>
                          <b-progress-bar
                            :value="item.estatisticaProva.qtdQuestoesErradas * 100"
                            variant="danger"
                          ></b-progress-bar>
                        </b-progress>
                      </div>
                    </td>
                    <td class="text-center" v-else>-</td>

                    <td class="text-left">
                      {{ item.dataHoraInicio | formatDate }}
                    </td>
                    <td v-if="item.estatisticaProva">
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="showResult(item.id)"
                      >
                        <i class="far fa-eye" />
                      </button>
                    </td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import moment from "moment";
import Navigator from "../../../components/resultados/ResultadosNavigator.vue";
import { constantes } from "@/constants";
import { checkUserHaveRole } from "@/tools";

export default {
  components: {
    Select2,
    MainTopBar,
    MainLeftSideBar,
    Navigator,
  },
  data() {
    return {
      showLeftMenu: true,
      provas: [],
      provasFiltradas: [],
      anos: [],
      disciplinas: [],
      ano: null,
      disciplina: null,
      instituicoesList: [],
      instituicoes: [],
      mostrarSelectInstituicoes: checkUserHaveRole([constantes.ROLE_SUPER_ADMIN, constantes.ROLE_CLIENTE_ADMIN]),
    };
  },
  computed: {
    disciplinasSelect() {
      return [{id: null, text: 'Selecione'}].concat(this.disciplinas)
    },
    anosSelect() {
      return [{id: null, titulo: 'Selecione'}].concat(this.anos)
    },
    instituicoesSelectList () {
      return this.instituicoesList.map(inst => {
        inst.text = inst.nome;
        return inst;
      })
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadProvas();
    this.loadDisciplinas();
    this.loadAnos();
    if(this.mostrarSelectInstituicoes){
      this.loadInstituicoes();
    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      } else {
        return "-";
      }
    },
  },
  methods: {
    calcularMedia(item){
      return item.estatisticaProva.media.toFixed(2);
      // return (item.media / item.prova.notaMaxima * 10).toFixed(2);
    },
    showResult(id) {
      this.$router.push("/resultados-alunos/" + id);
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");

      this.$api.get("ano_ensinos").then((response) => {
        this.anos = response.data.filter(res => res.trashAt == null)
        this.ano = this.anos[0] || null;
        this.$root.$emit("Spinner::hide");
        if(this.disciplina && this.ano){
          this.filtrar();
        }
      });
    },
    loadInstituicoes () {
      this.$api
        .get("instituicao_ensinos/todas")
        .then((res) => {
          this.instituicoesList = res.data.filter(res => res.trashAt == null)
          this.$root.$emit("Spinner::hide");
        })
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        this.disciplinas = response.data;
        this.disciplina = this.disciplinas[0] || null;
        this.$root.$emit("Spinner::hide");
        if(this.disciplina && this.ano){
          this.filtrar();
        }
      });
    },

    onChangeAno(e) {
      this.ano = e.target.value ? this.anos.find(ano => ano.id == e.target.value) : {id: null};
      this.filtrar();
    },

    onChangeInstituicoes(e) {
      this.filtrar();
    },

    filtrar(){
      // Para facilitar a experiência é melhor deixar as provas com estatística no topo da página
      this.provasFiltradas = this.provas.filter((data) => {
        if(this.instituicoes.length > 0){
          if(!this.instituicoes.includes('' + data.turma.instituicao.id)){
            return false;
          }
        }
        return data.turma.anoEnsino.id == this.ano.id && data.disciplina.id == this.disciplina.id;
      });
    },

    onChangeDisciplina(e) {
      this.disciplina = e.target.value ? this.disciplinas.find(disciplina => disciplina.id == e.target.value) : {id: null};
      this.filtrar();
    },
    clean() {
      window.location.reload();
    },
    loadProvas() {
      this.$api
        .get("/resultados-provas")
        .then((response) => {
          debugger;
          this.provas = response.data;
          if(this.disciplina && this.ano){
            this.filtrar();
          }
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    max-width: none;
  }
  #componente-curricular-col {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  #ano-col {
    padding-right: 20px;
  }
</style>